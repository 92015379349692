import mathLogo from "@src/assets/tokens/math-logo.png";
import turtleLogo from "@src/assets/tokens/turtle-logo.png";
import eggseggsLogo from "@src/assets/tokens/eggseggs-logo.jpeg";
import latinaLogo from "@src/assets/tokens/latina-logo.png";
import fomoLogo from "@src/assets/tokens/fomo-logo.jpeg";
import mundoLogo from "@src/assets/tokens/mundo-logo.jpeg";
import eggPointLogo from "@src/assets/tokens/eggpoint-logo.jpeg";
import eggLogo from "@src/assets/tokens/EGG.svg";
import endoLogo from "@src/assets/tokens/endo-logo.jpeg";
import marvinLogo from "@src/assets/tokens/marvin-logo.jpeg";
import eggmoonLogo from "@src/assets/tokens/ido-logo.jpeg";
import streetLogo from "@src/assets/tokens/street-logo.svg";
import kolkhozLogo from "@src/assets/tokens/kolkhoz-logo.jpeg";
import forkLogLogo from "@src/assets/tokens/cartel-logo.png";
import cguLogo from "@src/assets/tokens/cgu-logo.png";
import swopLogo from "@src/assets/tokens/SWOP.svg";
import nsbtLogo from "@src/assets/tokens/nsbt-logo.svg";
import ennoLogo from "@src/assets/tokens/enno-logo.svg";
import signLogo from "@src/assets/tokens/sign-logo.svg";
import duxplorerLogo from "@src/assets/tokens/duxplorer-logo.png";
import usdtLogo from "@src/assets/tokens/USDTERC20PPT.svg";
import puzzleLogo from "@src/assets/tokens/PUZZLE.svg";
import wavesLogo from "@src/assets/tokens/waves-logo.svg";
import usdnLogo from "@src/assets/tokens/usdn-logo.svg";
import raceLogo from "@src/assets/tokens/race-logo.svg";
import unknownLogo from "@src/assets/tokens/unknown-logo.svg";
import btcLogo from "@src/assets/tokens/bitcoin.svg";
import ltcLogo from "@src/assets/tokens/ltc.svg";
import ethLogo from "@src/assets/tokens/ethereum.svg";
import usdcLogo from "@src/assets/tokens/usdc.svg";
import eurn from "@src/assets/tokens/eurn.svg";
import wxLogo from "@src/assets/tokens/WX.svg";
import westLogo from "@src/assets/tokens/WEST.svg";
import wctLogo from "@src/assets/tokens/WCT.svg";
import munaLogo from "@src/assets/tokens/MUNA.svg";
import bnbLogo from "@src/assets/tokens/BNB.svg";
import shegLogo from "@src/assets/tokens/SHEG.png";
import yfiLogo from "@src/assets/tokens/YFI.svg";
import ftmLogo from "@src/assets/tokens/FTM.svg";
import linkLogo from "@src/assets/tokens/LINK.svg";
import uniLogo from "@src/assets/tokens/UNI.svg";
import daiLogo from "@src/assets/tokens/DAI.svg";
import tnLogo from "@src/assets/tokens/TN.svg";
import maticLogo from "@src/assets/tokens/MATIC.svg";
import shibLogo from "@src/assets/tokens/SHIB.svg";
import crvLogo from "@src/assets/tokens/CRV.png";
import snsbtLogo from "@src/assets/tokens/SNSBT.png";
import vladLogo from "@src/assets/tokens/VLAD.png";
import viresLogo from "@src/assets/tokens/vires.svg";
import eastLogo from "@src/assets/tokens/EAST.svg";
import usdtViresLogo from "@src/assets/tokens/USDT_VIRES.svg";
import usdcViresLogo from "@src/assets/tokens/USDC_VIRES.svg";
import tsnLogo from "@src/assets/tokens/TSN.svg";
import busdLogo from "@src/assets/tokens/BUSD.svg";
import wartLogo from "@src/assets/tokens/WART.svg";
import ngntLogo from "@src/assets/tokens/NGNT.svg";
import wxbLogo from "@src/assets/tokens/WXB.png";
import plutoLogo from "@src/assets/tokens/PLUTO.png";
import MINI from "@src/assets/tokens/mini.jpeg";
import POWER from "@src/assets/tokens/POWER.svg";
import SWAVES from "@src/assets/tokens/swaves.svg";
import SURF from "@src/assets/tokens/surf.svg";
import USDTWXG from "@src/assets/tokens/USDTWXG.svg";
import USDCWXG from "@src/assets/tokens/USDCWXG.svg";
import BNBWXG from "@src/assets/tokens/BNB WXG.svg";
import WBTCWXG from "@src/assets/tokens/WBTCWXG.svg";
import ETHWXG from "@src/assets/tokens/ETH-WXG.svg";
import oldgoldLogo from "@src/assets/tokens/OLDGOLD.jpg";
import pool5Logo from "@src/assets/tokens/5POOL.jpeg";
import pzWebLogo from "@src/assets/tokens/WEBPOOL.svg";
import plwavesLogo from "@src/assets/tokens/PLWAVES.svg";
import plwaves2Logo from "@src/assets/tokens/PL2WAVES.svg";
import plwaves3Logo from "@src/assets/tokens/PL3WAVES.svg";
import romeLogo from "@src/assets/tokens/ROME.svg";
import pzUnitsLogo from "@src/assets/tokens/UNITS.jpeg";
import pzUnitLogo from "@src/assets/tokens/UNIT.png";

const tokenLogos: Record<string, string> = {
  DUXPLORER: duxplorerLogo,
  MATH: mathLogo,
  TURTLE: turtleLogo,
  EGGSEGGS: eggseggsLogo,
  PESOLATINO: latinaLogo,
  FOMO: fomoLogo,
  MUNDO: mundoLogo,
  EGGPOINT: eggPointLogo,
  EGG: eggLogo,
  XTN: usdnLogo,
  ENDO: endoLogo,
  MARVIN: marvinLogo,
  EGGMOON: eggmoonLogo,
  STREET: streetLogo,
  KOLKHOZ: kolkhozLogo,
  FORKLOG: forkLogLogo,
  CGU: cguLogo,
  WAVES: wavesLogo,
  SWOP: swopLogo,
  NSBT: nsbtLogo,
  ENNO: ennoLogo,
  SIGN: signLogo,
  PUZZLE: puzzleLogo,
  RACE: raceLogo,
  WBTC: btcLogo,
  WETH: ethLogo,
  LTC: ltcLogo,
  WUSDC: usdcLogo,
  EURN: eurn,
  WX: wxLogo,
  WEST: westLogo,
  WCT: wctLogo,
  MUNA: munaLogo,
  WBNB: bnbLogo,
  SHEG: shegLogo,
  YFI: yfiLogo,
  MATIC: maticLogo,
  DAI: daiLogo,
  FTM: ftmLogo,
  CRV: crvLogo,
  TN: tnLogo,
  SHIB: shibLogo,
  UNI: uniLogo,
  LINK: linkLogo,
  SNSBT: snsbtLogo,
  VLAD: vladLogo,
  TSN: tsnLogo,
  BUSD: busdLogo,
  WART: wartLogo,
  NGNT: ngntLogo,
  WXB: wxbLogo,
  PLUTO: plutoLogo,
  VIRES_USDT_LP: usdtViresLogo,
  VIRES_USDC_LP: usdcViresLogo,
  MINI: MINI,
  SURF: SURF,
  POWER: POWER,
  SWAVES: SWAVES,
  USDT_WXG: USDTWXG,
  USDC_WXG: USDCWXG,
  WBTC_WXG: WBTCWXG,
  BNB_WXG: BNBWXG,
  ETH_WXG: ETHWXG,
  ETH: ethLogo,
  VIRES: viresLogo,
  EAST: eastLogo,
  SWOP_WAVESXTN: swopLogo,
  "PZ Old Gold": oldgoldLogo,
  "PZ Mega Pete": "https://images.wavesducks.com/api/images/DUCK-GGGGAAAA-GG.svg",
  SWOP_WXXTN: swopLogo,
  PLWAVES: plwavesLogo,
  PL2WAVES: plwaves2Logo,
  PL3WAVES: plwaves3Logo,
  ROME: "https://puzzleswap.org/static/media/ROME.187c0cabdf211ec145468f5bde145def.svg",
  "PZ 5pool": pool5Logo,
  "PZ WEB": pzWebLogo,
  "PZ WEBu": "https://puzzleswop.s3.amazonaws.com/f63b754d-8dd8-40c4-83aa-3dd1429a4e18",
  "PZ Units": pzUnitsLogo,
  "PZ Unit": pzUnitLogo,
  "WX-USDCUSDT-LP": "https://waves.exchange/static/icons/assets/rZMQ6g31Lr7sPAaAoYc4U2PHCVauTuKUSzUbJtUyPZN.svg",
  "USDT": usdtLogo,
  "USDC": "https://waves.exchange/static/icons/assets/HGgabTqUS8WtVFUJzfmrTDMgEccJuZLBPhFgQFxvnsoW.svg",
  WX_WAVESXTN: "https://waves.exchange/static/icons/assets/7KZbJrVopwJhkdwbe1eFDBbex4dkY63MxjTNjqXtrzj1.svg",
  WX_WXXTN: "https://waves.exchange/static/icons/assets/F2AKkA513k5yHEJkLsU6vWxCYYk811GpjLhwEv2WGwZ9.svg",
  WX_WXWAVES: "https://waves.exchange/static/icons/assets/BiSzFe8nSL78oZaebfoin5vBZ5Pze6d7kaeijLqr5xZe.svg",
  WX_WAVESEAST: "https://waves.exchange/static/icons/assets/6iMB6LKSrgv9waEvEnN6Ydyx7dfxPnGcTw8318WVm5bR.svg",
  "WX_sWAVESWAVES": "https://waves.exchange/static/icons/assets/DjgwW4CMJEfpzj7SLFFPiCqf1zfmAgf1MD5gAS8SjKt4.svg",
  "WX_ROMEUSDT": "https://waves.exchange/static/icons/assets/DsDN5YwWZQiTY5WJAVD5rd6WHKAZuuLYQK7v7YDGxFtR.svg",
  "WX_WAVESROME": "https://waves.exchange/static/icons/assets/3Kskka2WLEDtLbSwH37e5eZ3e9Ng8Z4DrZNErA4Af6e9.svg",
  "WX_ROMEXTN": "https://waves.exchange/static/icons/assets/DzRKHSQ4wibtJ3F1Y3jh5R8uSJpaQYuwVtfd9BHDqGAC.svg",
  "WX_WXROME": "https://waves.exchange/static/icons/assets/AuMs3E4K6c5K1CHr7x2pCNRXZ5ENFSUGWYKshZrex1Vc.svg",
  "WX_L2MPWX": "https://waves.exchange/static/icons/assets/3CvJkvzRkUp5PvLjuL8zN9y7zUigYyzyGKZTA3otfB94.svg",
  "WX_WXUSDT": "https://waves.exchange/static/icons/assets/K5TTvTbeyL4rrPyzrzwCuXfaHg2iuKEyEPMDEFrHuQv.svg",
  "WX_L2MPUSDT": "https://waves.exchange/static/icons/assets/GYkgBchN9answmT5G3bDf9uf135CNTeDdUbHy6QoqswX.svg",


  UNKNOWN: unknownLogo
};

export default tokenLogos;
